import React, { FC, useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useParams } from "react-router";
import {
  setCinemaAction,
  setHomeContent,
} from "../../store/application/action";
import { findCinemaObject, formatCinemaNearby, formatComingSoonAdvanceTicket, formatLocation } from "../../utils/formatter";
import Storage from "../../utils/storage";
import { toggleTicketModal } from "../../store/ticketBooking/action";
import { useNavigate } from "react-router-dom";
import SelectDropdown from "../../components/UI/DropDown/SelectDropDown";
import Modal from "../../components/UI/Modal";
import { API_REQUEST_TYPE, IMAGE_URL_CONFIG, URLS } from "../../constants/url";
import { COUNTRY } from "../../constants/app";
import "./style.scss";
import Sidebar from "./Sidebar";
import HelmetTitle from "../../components/Helmet";
import { getHomeContentApi } from "../../services/application";
import { getAdvanceTicketError, getAdvanceTicketSuccess, getComingSoonError, getComingSoonSuccess, getNowShowingError, getNowShowingFilterSuccess, getNowShowingSuccess, setLoaderAdvanceticket, setLoaderComingsoon, setLoaderNowshowing } from "../../store/films/action";
import { getContentApi } from "../../services/films";

interface MainProps {
  children: React.ReactNode;
}

const USLayout: FC<MainProps> = ({ children }) => {
  const { currentCinema, countryId, cinemas, quickFilters, nowShowingData } = useSelector(
    (state: RootState) => ({
      cinemas: state.applicationReducer.cinemas,
      currentCinema: state.applicationReducer.currentCinema,
      countryId: state.applicationReducer.countryId,
      quickFilters: state.filmsReducer.quickFilters,
      nowShowingData: state.filmsReducer.nowShowing
    })
  );

  const { cinemaId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [cinemaList, setCinemaList] = useState([]);

  useEffect(() => {
    document.body.classList.add("layout-us");
    document.body.classList.remove("layout-au");
  }, []);

  useEffect(() => {
    if (countryId && cinemas && cinemaList.length === 0) {
      setCinemaList(formatLocation(cinemas, true));
    }
  }, [countryId, cinemas]);

  // useEffect(() => {
  //   if (countryId && currentCinema) {
  //     dispatch(
  //       getMenuWatcher({
  //         countryId,
  //         cinemaId: currentCinema?.slug,
  //         flag: "menu",
  //       })
  //     );
  //   }
  // }, [countryId, currentCinema]);

  const checkGeoLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        let location = formatCinemaNearby(position, cinemas);
        const curcinema = findCinemaObject(cinemas, location);
        dispatch(setCinemaAction(curcinema));
        navigate(`/${curcinema && curcinema.slug ? `${curcinema.alias}` : ""}`);
        setOpenModal(false);
      },
      (error) => {
        setOpenModal(true);
      },
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 3000 }
    );
  }

  useEffect(() => {
    if (countryId === COUNTRY.AFC && currentCinema && currentCinema.slug === '0000000008') {
      window.location.href = URLS.PLANO_REDIRECT_URL;
      Storage.removeItem("LOCAL", "currentCinema");
    }
  }, [currentCinema])

  useEffect(() => {
    if (
      (!currentCinema || currentCinema.alias !== cinemaId) &&
      cinemaId &&
      Object.keys(cinemas).length > 0
    ) {
      const cinema = findCinemaObject(cinemas, cinemaId);
      if (cinema) {
        dispatch(setCinemaAction(cinema));
      }
    } else {
      const sessonCinema = Storage.getItem("LOCAL", "currentCinema");
      if (
        countryId &&
        cinemas &&
        sessonCinema &&
        Object.keys(sessonCinema).length > 0 &&
        !currentCinema
      ) {
        const storageCinema = findCinemaObject(cinemas, sessonCinema?.alias);
        if (storageCinema) {
          dispatch(setCinemaAction(storageCinema));
          setOpenModal(false);
          navigate(`/${storageCinema.alias!}`);
        } else {
          // checkGeoLocation();
          setOpenModal(true);
          dispatch(toggleTicketModal(false));
        }
      } else if (
        countryId &&
        cinemas &&
        (!currentCinema || Object.keys(currentCinema).length === 0)
      ) {
        if (sessonCinema.alias) {
          navigate(`/${sessonCinema.alias}`);
        }
        // checkGeoLocation();
        setOpenModal(true);
        dispatch(toggleTicketModal(false));
      }
    }
  }, [cinemas, cinemaId]);

  useEffect(() => {
    window.onpopstate = function (event) {
      window.history.pushState(null, "", window.location.href);
    };
  }, []);

  useEffect(() => {
    if (currentCinema) {
      window.history.pushState(null, "", window.location.href);
    }
  }, [currentCinema]);

  const onChangeCinema = (value: string) => {
    if (cinemas) {
      const curcinema = findCinemaObject(cinemas, value);
      dispatch(setCinemaAction(curcinema));
      navigate(`/${curcinema && curcinema.slug ? `${curcinema.alias}` : ""}`);
      setOpenModal(false);
    }
  };

  const getHomeContent = () => {
    if (countryId && currentCinema) {
      // Home page content
      getHomeContentApi({
        countryId: countryId,
        cinemaId: currentCinema?.slug,
      }).then((response) => dispatch(setHomeContent(response)));

      // Movie shows content
      dispatch(setLoaderNowshowing(true));
      dispatch(setLoaderComingsoon(true));
      dispatch(setLoaderAdvanceticket(true));
      getContentApi({
        countryId: countryId,
        cinemaId: currentCinema?.slug,
        status: API_REQUEST_TYPE.GET_SHOWS,
        flag: 'initial',
        selectedDate: nowShowingData.length > 0 && quickFilters.date ? quickFilters.date : ''
      }).then((showResponse) => {
        dispatch(setLoaderNowshowing(false));
        dispatch(setLoaderComingsoon(false));
        dispatch(setLoaderAdvanceticket(false));
        const {
          data: { nowShowing, advanceTicket, comingSoon },
        } = showResponse;

        if (nowShowing && nowShowing.statusCode === 200) {
          if (
            nowShowing.data &&
            nowShowing.data.movies &&
            nowShowing.data.movies.length > 0
          ) {
            dispatch(getNowShowingSuccess(nowShowing.data.movies));
          } else {
            dispatch(getNowShowingError([]));
          }

          if (nowShowing.data && nowShowing.data.filter) {
            dispatch(getNowShowingFilterSuccess(nowShowing.data.filter));
          }
        }

        if (comingSoon && comingSoon.statusCode === 200) {
          dispatch(
            getComingSoonSuccess(
              formatComingSoonAdvanceTicket(
                comingSoon.data.length > 0 ? comingSoon.data : [],
                advanceTicket.data &&
                  advanceTicket.data.cmgSessions &&
                  advanceTicket.data.cmgSessions.length > 0
                  ? advanceTicket.data.cmgSessions
                  : []
              )
            )
          );
        } else {
          dispatch(getComingSoonError([]));
        }

        if (
          advanceTicket &&
          advanceTicket.statusCode === 200 &&
          advanceTicket.data &&
          advanceTicket.data.advSessions &&
          advanceTicket.data.advSessions.length > 0
        ) {
          dispatch(getAdvanceTicketSuccess(advanceTicket.data.advSessions));
        } else {
          dispatch(getAdvanceTicketError([]));
        }
      });
    }
  };

  useEffect(() => {
    if (countryId && currentCinema && currentCinema.slug) {
      getHomeContent();
    }
  }, [countryId, currentCinema]);

  return (
    <>
      <HelmetTitle title="Movie Information | Tickets | Showtimes" description="Movie Information | Tickets | Showtimes" />
      <div className={`layout-us ${countryId === COUNTRY.CON ? 'consolidated' : countryId === COUNTRY.AFC ? 'angelika-film-center' : ''}`}>
        <Sidebar />
        <Header />
        <div>{children}</div>
        <Footer />
      </div>
      <Modal
        className={`header-us-modal ${countryId === COUNTRY.CON ? 'consolidated' : countryId === COUNTRY.AFC ? 'angelika-film-center' : ''}`}
        visible={openModal}
        showCloseBtn={false}
      >
        <div className="modal fade show location-notification" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <div className="logo">
                  <img
                    width={100}
                    src={
                      IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                      `${countryId === COUNTRY.CON
                        ? "con/consolidated_logo.svg"
                        : countryId === COUNTRY.AFC
                          ? "afc/angelika_logo.svg"
                          : "reading_logo_us.svg"
                      }`
                    }
                  />
                </div>
              </div>
              <div className="modal-body">
                <div className="notification-content">
                  <p>We’ve got a new look! Welcome to the new {countryId === COUNTRY.CON ? 'Consolidated theatres' : countryId === COUNTRY.AFC ? 'Angelika' : 'Reading Cinemas US'} website!</p>
                  <p>If you had a user account on our old website, {countryId === COUNTRY.CON ? 'consolidatedtheatres.com' : countryId === COUNTRY.AFC ? 'angelikafilmcenter.com' : 'readingcinemasus.com'}, for security reasons, you'll need to reset your password prior to accessing your account.</p>
                  <p>Questions along the way? Feel free to contact us at{' '}
                    {countryId === COUNTRY.CON ? <a className="text-red" href="mailto:help@consolidatedtheatres.com">help@consolidatedtheatres.com</a> :
                      countryId === COUNTRY.AFC ? <a className="text-red" href="mailto:help@angelikafilmcenter.com">help@angelikafilmcenter.com</a> :
                        <a className="text-red" href="mailto:help@readingcinemas.com">help@readingcinemas.com</a>}
                  </p>
                  <p>No user account yet? No problem! Register for free, and we'll look forward to seeing you at the movies!</p>
                  <p>Select your cinema from the dropdown below to continue.</p>
                </div>
                <div className="my-4 loc-dropdown">
                  <SelectDropdown
                    placeholder="Select your cinema"
                    options={cinemaList}
                    onChange={onChangeCinema}
                    field={"location"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default USLayout;
