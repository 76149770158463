import React, { FC, useEffect, useMemo, useState } from "react";
import Modal from "../../../components/UI/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setContentModal,
  toggleContentModal,
} from "../../../store/application/action";
import { RootState } from "../../../store";
import ContactUs from "./ContactUs";
import { URLS } from "../../../constants/url";
import PrivacyPolicy from "./PrivacyPolicy";
import RatingIndex from "./RatingIndex";
import { ROUTES } from "../../../constants/path";
import { useNavigate } from "react-router-dom";
import NewsLetter from "../../US/Home/NewsLetter";
import Employment from "./Employment";
import GroupBookings from "./GroupSale";
import CinemaHire from "./CinemaHire";
import CinemaHireUs from './CinemaHireUs';
import VipExperienceForm from "./VipCinemas";
import ResetPassword from "./ResetPassword";
import CompetitionModal from "./Competition";
import { BRANDS } from "../../../constants/app";
import CorporateCinemony from "./CorporateCinemony";
import EmailUpdate from "./EmailUpdate";

interface IContentModalProps {}

const ContentModal: FC<IContentModalProps> = () => {
  const { brandId, openContentModal, contentModal, currentCinema } =
    useSelector((state: RootState) => ({
      brandId: state.applicationReducer.brandId,
      openContentModal: state.applicationReducer.openContentModal,
      contentModal: state.applicationReducer.contentModal,
      currentCinema: state.applicationReducer.currentCinema,
    }));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onCloseModal = () => {
    dispatch(
      setContentModal({
        type: contentModal.prevModal ? contentModal.prevModal : "",
        values: contentModal.values ? contentModal.values : null,
      })
    );
    if (!contentModal.prevModal) {
      dispatch(toggleContentModal(false));
    }
    if (
      brandId === BRANDS.US &&
      !["Promo Terms & Condition","Employment Privacy Policy",ROUTES.CAREER].includes(contentModal.type)
    ) {
      navigate(`/${currentCinema?.alias}`);
    }
    if (brandId === BRANDS.AU && contentModal.type === ROUTES.RESET_PASSWORD) {
      navigate(`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`);
    }
  };

  const Content = useMemo(() => {
    switch (contentModal.type) {
      case ROUTES.CONTACT_US:
        return <ContactUs onCloseModal={onCloseModal} />;
      case ROUTES.PRIVACY:
        return <PrivacyPolicy type="Privacy Policy" />;
      case ROUTES.PRIVACY_POLICY_US_STATE:
        return <PrivacyPolicy type="Your US State Privacy Rights" />;
      case ROUTES.PRIVACY_POLICY_CHILDREN:
        return <PrivacyPolicy type="Children's Online Privacy Policy" />;
      case ROUTES.INVESTORS_BASED_ADS:
        return <PrivacyPolicy type="Interest-Based Ads" />;
      case ROUTES.PERSONAL_INFORMATION:
        return (
          <PrivacyPolicy type="Do Not Sell or Share My Personal Information" />
        );
      case "Etix Terms":
        return <PrivacyPolicy type="Etix Terms" />;
      case "Rewards Etix Terms":
        return <PrivacyPolicy type="Rewards Terms & Condition - Angelika" />;
      case ROUTES.TERMS:
        return (
          <PrivacyPolicy
            type={`${brandId === BRANDS.US ? "Terms of Use" : "Terms"}`}
          />
        );
      case ROUTES.SITE_DISCLAIMER:
        return <PrivacyPolicy type={"Site Disclaimer"} />;
      case "Gift Shop Terms":
        return <PrivacyPolicy type="Gift Shop Terms" />;
      case "Promo Terms & Condition":  
      return <PrivacyPolicy type="Promo Terms & Condition" />;
      case "Group Booking Terms":
        return <PrivacyPolicy type="Group Booking Terms" />;
      case ROUTES.RATING:
        return <RatingIndex />;
      case ROUTES.NEWS_LETTER:
        return <NewsLetter fromPage="newsletter-modal" />;
      case ROUTES.CAREER:
        return <Employment onCloseModal={onCloseModal} />;
      case ROUTES.GROUP_SALE:
        return <GroupBookings onCloseModal={onCloseModal} />;
      case ROUTES.CINEMA_HIRE:
        return brandId === BRANDS.US?<CinemaHireUs onCloseModal={onCloseModal} />:<CinemaHire onCloseModal={onCloseModal} />;
      case ROUTES.VIP_EXPERIENCE:
        return <VipExperienceForm onCloseModal={onCloseModal} />;
      case ROUTES.COMPETITIONS:
        return <CompetitionModal />;
      case ROUTES.CINEMONY:
        return <CorporateCinemony />;
      case ROUTES.MAILINGLIST:
        return <EmailUpdate onCloseModal={onCloseModal} />;
      case ROUTES.RESET_PASSWORD:
        return (
          <ResetPassword code={contentModal.code} onCloseModal={onCloseModal} />
        );
      case "Employment Privacy Policy":
        return <PrivacyPolicy type="Employment Privacy Policy"/> 
      default:
        return null;
    }
  }, [contentModal?.type]);

  return (
    <Modal
      className="ticket-booking-modal"
      visible={openContentModal}
      showCloseBtn={false}
    >
      <div className="modal fade show" style={{ display: "block" }}>
        <div
          className={`modal-dialog modal-dialog-centered ${
            contentModal?.type === ROUTES.RESET_PASSWORD
              ? "modal-md"
              : "modal-lg"
          }`}
        >
          <div className="modal-content">
            <div className="modal-header-image">
              <button
                type="button"
                className="close-modal"
                onClick={onCloseModal}
              >
                <img
                  className="close_icon"
                  src={URLS.CLOSE_ICON}
                  alt="close_icon"
                  title="close_icon"
                />
              </button>
            </div>
            {Content}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContentModal;
